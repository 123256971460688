const USERKEY = "username";
const TOKENKEY = "token";

export const getUser = () => {
  return window.localStorage.getItem(USERKEY);
};

export const getToken = () => {
  return window.localStorage.getItem(TOKENKEY);
};

export const saveUser = username => {
  window.localStorage.setItem(USERKEY, username);
};

export const saveToken = token => {
  window.localStorage.setItem(TOKENKEY, token);
};

export const destroy = () => {
  window.localStorage.removeItem(USERKEY);
  window.localStorage.removeItem(TOKENKEY);
};

export default { getUser, getToken, saveUser, saveToken, destroy };
