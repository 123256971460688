import ApiService from "@/core/services/api.service";
import AuthService from "@/core/services/auth.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!AuthService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.response);
          ApiService.init();
          resolve(data);
        })
        .catch(({ response }) => {
          var errors = [];
          errors.push(response.data.error.message);
          context.commit(SET_ERROR, errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    if (AuthService.getToken()) {
      return new Promise(resolve => {
        ApiService.get("verify")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(() => {
            context.commit(PURGE_AUTH);
            window.location = "/";
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.username = data.username;
    state.token = data.token;
    state.errors = {};
    AuthService.saveUser(state.username);
    AuthService.saveToken(state.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.username = null;
    state.token = null;
    state.errors = {};
    AuthService.destroy();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
