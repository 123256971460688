import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/dailyHoroscope",
          name: "dailyHoroscope",
          component: () => import("@/view/pages/DailyHoroscope.vue")
        },
        {
          path: "/dailyHoroscopeForm",
          name: "dailyHoroscopeForm",
          component: () => import("@/view/pages/DailyHoroscopeForm.vue")
        },
        {
          path: "/dailyHoroscopeBuilder",
          name: "dailyHoroscopeBuilder",
          component: () => import("@/view/pages/DailyHoroscopeBuilder.vue")
        },
        {
          path: "/monthlyHoroscope",
          name: "monthlyHoroscope",
          component: () => import("@/view/pages/MonthlyHoroscope.vue")
        },
        {
          path: "/monthlyHoroscopeForm",
          name: "monthlyHoroscopeForm",
          component: () => import("@/view/pages/MonthlyHoroscopeForm.vue")
        },
        {
          path: "/monthlyHoroscopeBuilder",
          name: "monthlyHoroscopeBuilder",
          component: () => import("@/view/pages/MonthlyHoroscopeBuilder.vue")
        },
        {
          path: "/posts",
          name: "posts",
          component: () => import("@/view/pages/Posts.vue")
        },
        {
          path: "/postForm",
          name: "postForm",
          component: () => import("@/view/pages/PostForm.vue")
        },
        {
          path: "/notes",
          name: "note",
          component: () => import("@/view/pages/Notes.vue")
        },
        {
          path: "/noteForm",
          name: "noteForm",
          component: () => import("@/view/pages/NoteForm.vue")
        },
        {
          path: "/calendarDays",
          name: "calendarDays",
          component: () => import("@/view/pages/CalendarDays.vue")
        },
        {
          path: "/calendarDayForm",
          name: "calendarDayForm",
          component: () => import("@/view/pages/CalendarDayForm.vue")
        },
        {
          path: "/subscriptions",
          name: "subscriptions",
          component: () => import("@/view/pages/Subscriptions.vue")
        },
        {
          path: "/subscriptionForm",
          name: "subscriptionForm",
          component: () => import("@/view/pages/SubscriptionForm.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/Settings.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});
