import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AuthService from "@/core/services/auth.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.API_URL;
    //Vue.axios.defaults.withCredentials = true;
    if (AuthService.getToken()) {
      Vue.axios.defaults.headers.common["Authorization"] =
        "Bearer " + AuthService.getToken();
    }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, params = {}) {
    let url = resource;
    let searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if (typeof params[key] == "object") {
        Object.keys(params[key]).forEach(key2 => {
          searchParams.append(key + "[" + key2 + "]", params[key][key2]);
        });
      } else {
        searchParams.append(key, params[key]);
      }
    });

    if (Object.keys(params).length > 0) {
      url = url + "?" + searchParams.toString();
    }

    return Vue.axios.get(url).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
